
import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {
  useDispatch, useSelector,
} from "react-redux";
import { useTranslation } from "react-i18next";
import AgreementDetails from "pages/ClientDetail/Partnership/AgreementDetails";

export default function AgreementDetailsModal({ agreement = {} }) {

  const [open, setOpen] = React.useState(false);
  const toggleModal = () => setOpen(!open);
  const { t  } = useTranslation();
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);


  return (
    <React.Fragment>
      <button 
        type="button" 
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        View Agreement
      </button>
      <Modal isOpen={open} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>
        Agreement
        </ModalHeader>
        <ModalBody>
          {
            agreement?._id ? <AgreementDetails
              agreement={agreement}
              accountTypes={accountTypes}
              t={t}
            /> : <p>{"No Agreement found"}</p>
          }
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}