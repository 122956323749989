import { getTradingAccountByCustomerId } from "apis/tradingAccounts";
import SearchableComponent from "pages/Transactions/Forex/internalTransfer/SearchableComponents";
import React, { useEffect, useState } from "react";

export default function SearchableInvestorAccount({
  investor: inv, setAccount, profile
}) {
  const [investor, setInvestor] = useState({});
  const [acc, setAcc] = useState();

  useEffect(() => {
    setInvestor(inv);
    setAcc();
    if (setAccount) setAccount();
  }, [inv]);

  return (
    <SearchableComponent
      placeholder={("Select Investor Account")}
      label={"Investor Account"}
      name={"investorAccount"}
      onChange={(e) => {
        if (setAccount) {
          if (e?.value) {
            setAcc(e.value);
            setAccount(e.value);
          } else {
            setAcc(e);
            setAccount(e);
          }
        } else {
          e?.value ? setAcc(e.value) : setAcc(e);
        }
      }}
      getData={
        async () => getTradingAccountByCustomerId({ 
          payload: {
            customerId: investor?._id || inv?._id,
            page: 1,
            limit: 1000,
          }
          // TODO: Enable this when we have the API
        }).then((res) => {
          if ((res?.result?.docs)) {
            return res.result?.docs?.filter((doc) => doc?.type === "INVESTOR") || [];
          }
          return [];
        })
      }
      mapper={(doc)=> (
        {
          label: `${doc.login} | ${doc.platform} | ${doc?.accountTypeId?.title}`,
          value: doc
        }
      )}
      value={acc}
      shouldReset={`${investor._id}:${profile?._id}`}
    />
  );
}
