import { updateCustomerPlanType } from "apis/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from "reactstrap";
import { showErrorNotification, showSuccessNotification } from "store/actions";
import { fetchClientDetails, fetchClientsStart } from "store/client/actions";

export default function ReferralDetails({
  clientId, isLead, planType
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const getPlanType = () => {
    switch (planType) {
      case 0:
        return "Not Configured";
      case 1:
        return "Standard";
      case 2:
        return "Pro";
      case 3:
        return "Affiliate";
      default:
        return "Not Set";
    }
  };
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const updatePlan = (e) => {
    const newPlan = e.value;
    setLoading(true);
    updateCustomerPlanType({
      clientId,
      planType: newPlan
    })
      .then(() => {
        setLoading(false);
        dispatch(showSuccessNotification("Plan Updated Successfully"));
        dispatch(fetchClientDetails(clientId));
        toggle();
      }).catch((e) => {
        dispatch(showErrorNotification(e.message));
        setLoading(false);
      });
  };

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead }
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggle}
      >
        {t("View/Edit Plan")}
      </button>
      <Modal isOpen={isOpen} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("View/Edit Plan")}
        </ModalHeader>
        <ModalBody >
          <Row>
            <Col md="12">
              <h5>Plan Details</h5>
              <Row>
                <h6>Your Plan is: {getPlanType()}</h6>
              </Row>
            </Col>
          </Row>
          <Row>
            { loading ? <div className="d-flex justify-content-center">
              <Spinner />
            </div> : <>
              <ReactSelect
                className="react-select"
                classNamePrefix="react-select"
                isDisabled={isLead}
                options={[
                  {
                    value: 1,
                    label: "Standard"
                  },
                  {
                    value: 2,
                    label: "Pro"
                  },
                  {
                    value: 3,
                    label: "Affiliate"
                  },
                ]}
                defaultValue
                value={{
                  value: planType,
                  label: getPlanType()
                }}
                onChange={updatePlan}
              />
            </>}
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
}
