const LOG_TYPES = {
  REGISTER: "REGISTER",
  LOGIN: "LOGIN",
  CONVERT_CUSTOMER: "CONVERT_CUSTOMER",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  AVATAR_UPDATED: "AVATAR_UPDATED",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  CONVERT: "CONVERT",
  ORDER: "ORDER",
  WALLET: "WALLET",
  // docs
  UPLOAD_DOCS: "UPLOAD_DOCS",
  OVERWRITE_DOCS: "OVERWRITE_DOCS",
  CHANGE_DOC_STATUS: "CHANGE_DOC_STATUS",
  // bank accs
  ADD_BANK_ACCOUNT: "ADD_BANK_ACCOUNT",
  EDIT_BANK_ACCOUNT: "EDIT_BANK_ACCOUNT",
  DELETE_BANK_ACCOUNT: "DELETE_BANK_ACCOUNT",
  // todos
  ADD_TODO: "ADD_TODO",
  EDIT_TODO: "EDIT_TODO",
  DELETE_TODO: "DELETE_TODO",
  // leads
  UPDATE_LEAD_STAGE: "UPDATE_LEAD_STAGE",
  // customers
  EDIT_CUSTOMER_INFO: "EDIT_CUSTOMER_INFO",
  PROFILE_COMPLETED: "PROFILE_COMPLETED",

  // 2fa
  ENABLE_2FA: "ENABLE_2FA",
  DISABLE_2FA: "DISABLE_2FA",

  // requests
  IB_REQUEST: "IB_REQUEST",
  UPDATE_IB_REQUEST: "UPDATE_IB_REQUEST",
  CREATE_ACCOUNT_REQUEST: "CREATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_REQUEST: "UPDATE_ACCOUNT_REQUEST",
  LEVERAGE_REQUEST: "LEVERAGE_REQUEST",
  UPDATE_LEVERAGE_REQUEST: "UPDATE_LEVERAGE_REQUEST",
  // create trading accounts
  ACCOUNT_CREATED: "ACCOUNT_CREATED",
  // transactions
  FX_DEPOSIT: "FX_DEPOSIT",
  FX_DEPOSIT_UPDATE: "FX_DEPOSIT_UPDATE",
  FX_WITHDRAW: "FX_WITHDRAW",
  FX_WITHDRAW_UPDATE: "FX_WITHDRAW_UPDATE",
  FX_INTERNAL_TRANSFER: "FX_INTERNAL_TRANSFER",
  FX_INTERNAL_TRANSFER_UPDATE: "FX_INTERNAL_TRANSFER_UPDATE",
  FX_CREDIT_UPDATE: "FX_CREDIT_UPDATE",
  FX_DEPOSIT_AUTO: "FX_DEPOSIT_AUTO",
  FX_WITHDRAW_AUTO: "FX_WITHDRAW_AUTO",
  FX_INTERNAL_TRANSFER_AUTO: "FX_INTERNAL_TRANSFER_AUTO",

  // users
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  // teams
  CREATE_TEAM: "CREATE_TEAM",
  UPDATE_TEAM: "UPDATE_TEAM",
  ADD_TEAM_MEMBER: "ADD_TEAM_MEMBER",
  DELETE_TEAM_MEMBER: "DELETE_TEAM_MEMBER",
  // roles
  ROLES: "ROLES",
  UPDATE_ROLE: "UPDATE_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  // uncategorized
  CREATE_LEAD: "CREATE_LEAD",
  ASSIGN_AGENT: "ASSIGN_AGENT",
  CHANGE_CALL_STATUS: "CHANGE_CALL_STATUS",
  SYSTEM_EMAIL: "SYSTEM_EMAIL",

  // CAMPAIGNS
  ADD_CAMPAIGN_TEMPLATE: "ADD_CAMPAIGN_TEMPLATE",
  EDIT_CAMPAIGN_TEMPLATE: "EDIT_CAMPAIGN_TEMPLATE",
  DELETE_CAMPAIGN_TEMPLATE: "DELETE_CAMPAIGN_TEMPLATE",
  CAMPAIGN_UNSUBSCRIBE: "CAMPAIGN_UNSUBSCRIBE",
  ADD_CAMPAIGN: "ADD_CAMPAIGN",
  EDIT_CAMPAIGN: "EDIT_CAMPAIGN",
  DELETE_CAMPAIGN: "DELETE_CAMPAIGN",
  RUN_CAMPAIGN: "RUN_CAMPAIGN",
  // IB
  LINK_IB: "LINK_IB",
  UNLINK_IB: "UNLINK_IB",
  // MASTER AGREEMENT
  CREATE_MASTER_AGREEMENT: "CREATE_MASTER_AGREEMENT",
  UPDATE_MASTER_AGREEMENT: "UPDATE_MASTER_AGREEMENT",
  // SHARED
  CREATE_SHARED_AGREEMENT: "CREATE_SHARED_AGREEMENT",
  UPDATE_SHARED_AGREEMENT: "UPDATE_SHARED_AGREEMENT",
  // Delete agreement
  DELETE_AGREEMENT: "DELETE_AGREEMENT",
  // Copy Trading
  PROFILE_REQUESTED: "PROFILE_REQUESTED",
  PROFILE_APPROVED: "PROFILE_APPROVED",
  PROFILE_REJECTED: "PROFILE_REJECTED",

  PROFILE_LINK_REQUESTED: "PROFILE_LINK_REQUESTED",
  PROFILE_LINK_APPROVED: "PROFILE_LINK_APPROVED",
  PROFILE_LINK_REJECTED: "PROFILE_LINK_REJECTED",

  PROFILE_UNLINK_REQUESTED: "PROFILE_UNLINK_REQUESTED",
  PROFILE_UNLINK_APPROVED: "PROFILE_UNLINK_APPROVED",
  PROFILE_UNLINK_REJECTED: "PROFILE_UNLINK_REJECTED",

  INVESTOR_REQUESTED: "INVESTOR_REQUESTED",
  INVESTOR_APPROVED: "INVESTOR_APPROVED",
  INVESTOR_REJECTED: "INVESTOR_REJECTED",
  USER_EMAIL: "USER_EMAIL",

};

const LOG_LEVELS = {
  0: "DEBUG",
  1: "INFO",
  2: "WARN",
  3: "ERROR",
};

const TRIGGERED_BY = {
  0: "Client",
  1: "User"
};

export {
  LOG_LEVELS,
  LOG_TYPES,
  TRIGGERED_BY,
};