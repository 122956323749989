import NewMamModal from "pages/ClientDetail/MamProfiles/NewMamModal";
import ApproveDetailsModal from "pages/Requests/Master/ApproveDetailsModal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { createMamProfile } from "store/mam-pam/actions";

export default function CreateMamProfile({
  clientId
}) {
  const { t } = useTranslation();
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);
  const dispatch = useDispatch();
  return (
    <>
      <Button color="primary" onClick={toggleModal}>
        {t("New Copy Trading Profile")}
      </Button>
      <ApproveDetailsModal isOpen={modal} toggleModal={toggleModal}  handler={(values) =>{
        dispatch(createMamProfile({
          ...values,
          customerId: clientId,
        }));
        toggleModal();
      }} title={t("New Copy Trading Profile")}
      />
    </>
  );
}
