import React, {
  useState, useEffect
} from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Alert,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "../SearchableInputStyles.scss";
import { withTranslation } from "react-i18next";
import { addInternalTransfer } from "store/forexTransactions/internalTransfers/actions";
import {
  fetchTradingAccountByCustomerId
} from "store/tradingAccounts/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import SearchableComponent from "./SearchableComponents";
import { getClients, getReferrals } from "apis/client";
import { fetchClientWallets } from "store/wallet/actions";
import TableLoader from "components/Common/Loader";
import usePermissions from "routes/permissions";
import { fetchConversionRates } from "store/actions";
import { getTradingAccountByCustomerId } from "apis/tradingAccounts";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";
import { getClientWalletDetails } from "apis/wallet";
import { enableClientToClientTransfer } from "config";
import { getConversionRate } from "apis/conversionRates";
import TransactionLimitAlert from "../TransactionLimit";
import { startCase } from "lodash";

function AddInternalTransferModal(props) {
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [from, setFrom] = useState("");
  const [fromCustomer, setFromCustomer] = useState(undefined);
  const [fromDetails, setFromDetails] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [amount, setAmount] = useState(0);
  const [ibTransactionType, setIbTransactionType] = useState(null);
  const [ibClient, setIbClient] = useState(null);
  const [toIbOptions, setToIbOptions] = useState([]);
  const [isAmountValid, setIsAmountValid] = useState(false);

  const {
    clientData
  } = useSelector((state) => ({
    clientData: state.clientReducer.clientData || {},
  }));

  const {
    wallets,
    walletsLoading,
  } = useSelector((state) => ({
    wallets: state.walletReducer?.wallet?.docs || [],
    walletsLoading: state.walletReducer?.loading || false,
  }));

  const {
    accounts,
    accountsLoading,
  } = useSelector((state) => ({
    accounts: state.tradingAccountReducer.customerTradingAccounts || [],
    accountsLoading: state.tradingAccountReducer.loading || false,
  }));

  const transferSuccess = useSelector((state) => (state.internalTransferReducer.modalClear));

  const { addInternalTransferClearingCounter } = useSelector(
    (state) => state.internalTransferReducer
  );

  const {
    withdrawalsPermissions,
  } = usePermissions();
  const { create } = withdrawalsPermissions;

  useEffect(() => {
    props.show && setAddModal(!addModal);
  }, [props.show]);

  useEffect(() => {
    transferSuccess && setAddModal(false);
  }, [transferSuccess]);

  const cleanUp = () => {
    setTo("");
    setFrom("");
    setFromDetails(undefined);
    setFromCustomer(undefined);
    setIbTransactionType(null);
    setAmount(0);
  };

  const toggleAddModal = () => {
    cleanUp();
    setAddModal(!addModal);
  };

  useEffect(() => {
    if (!props.disableAddButton && open) {
      setAddModal(false);
    }
  }, [props.modalClear]);

  useEffect(() => {
    if (amount > 0 && fromDetails) {
      if (fromDetails?.amount) {
        console.log("wallet");
        setIsAmountValid(amount <= fromDetails?.amount);
      }
      if (fromDetails?.Balance) {
        console.log("account");
        setIsAmountValid(amount <= parseInt(fromDetails?.Balance));
      }
      
    } else {
      setIsAmountValid(false);
    }
  }, [amount, fromDetails]);

  const handleAddForexInternalTransfer = (e, v) => {
    const payload = {
      fromId: fromDetails._id,
      toId: to._id,
      amount,
      source: v?.from === "fx" ? "FX" : "WALLET",
      destination: to?.accountTypeId ? "FX" : "WALLET",
      transactionType: !v?.ibClient ? "SELF_TRANSFER" : "IB_TRANSFER",
      isClientToClient: ibTransactionType === "client",
    };
    dispatch(addInternalTransfer({
      ...payload,
    }));
  };

  const loadWalletsByCustomerId = (customerId) => {
    dispatch(fetchClientWallets({
      belongsTo: customerId,
      page: 1,
      limit: 100,
      isCrypto: false,
    }));
  };

  const loadTradingAccountsByCustomerId = (customerId) => {
    dispatch(fetchTradingAccountByCustomerId({
      customerId,
      page: 1,
      limit: 100,
    }));
  };

  useEffect(() => {
    if (fromCustomer) {
      loadWalletsByCustomerId(fromCustomer._id);
      loadTradingAccountsByCustomerId(fromCustomer._id);
    } 
    // if (props.customerId) {
    //   loadWalletsByCustomerId(props.customerId);
    //   loadTradingAccountsByCustomerId(props.customerId);
    // }
  }, [fromCustomer]);
  useEffect(()=> {
    if (props.customerId) {
      setFromCustomer({
        ...clientData,
        _id: props.customerId._id,
      });
    }
  }, []);

  useEffect(() => {
    if (fromDetails && to && fromDetails.currency !== to.currency) {
      dispatch(fetchConversionRates({
        baseCurrency: fromDetails.currency,
        targetCurrency: to.currency,
      }));
    }
  }, [fromDetails, to]);

  useEffect(() => {
    if (fromDetails && ibTransactionType !== "self" && ibClient) {
      (async () => {
        const walletResp = await getClientWalletDetails({
          payload: {
            belongsTo: ibClient._id,
            customerId: ibClient._id,
            page: 1,
            limit: 100,
            isCrypto: false,
          }
        });
        const accResp = await getTradingAccountByCustomerId({
          payload: {
            customerId: ibClient._id,
            page: 1,
            limit: 100,
          }
        });
        const wallets = walletResp?.result?.docs || [];
        const accounts = accResp?.result?.docs || [];
        const options = [];
        wallets.filter(w => w._id !== fromDetails._id).map((wallet,) => {
          options.push({
            label: `${wallet?.isFeesWallet ? `Fee Wallet - ${startCase(wallet.asset)}` : startCase(wallet.asset)} - (Balance ${wallet.amount} ${wallet.asset})`,
            value: {
              ...wallet,
              currency: wallet.asset,
            },
          });
        }
        );
        accounts?.filter(a=> a.type !== "MAM_MASTER").map((account) => {
          options.push({
            label: `${account?.platform} - ${account?.accountTypeId?.title} | ${account?.login} | BAL: ${account?.Balance} ${account?.currency}`,
            value: account,
          });
        }
        );
        setToIbOptions(options);
      })();
    }
    if (ibTransactionType === "self") {
      setToIbOptions([]);
      setIbClient(null);
    }
  }, [fromDetails, ibTransactionType, ibClient]);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    addModal && toggleAddModal();
  }, [addInternalTransferClearingCounter]);


  const getClientToOptions = () => {
    const options = [];
    if (fromDetails) {
      wallets.filter(w => w._id !== fromDetails._id)?.filter(w => !w?.isFeesWallet).map((wallet) => {
        options.push({
          label: `${wallet.asset} | BAL: ${wallet.amount}`,
          value: {
            ...wallet,
            currency: wallet.asset,
          },
        });
      });
    }
    if (fromCustomer || props.customerId) {
      accounts
        .filter((account) => account?._id !== fromDetails?._id && account.type !== "MAM_MASTER" && account.type !== "DEMO").map((account) => {
          options.push({
            label: `${account?.platform} - ${account?.accountTypeId?.title} | ${account?.login} | BAL: ${account?.Balance} ${account?.currency}`,
            value: account,
          });
        });
    }
    return options;
  };

  const [isConversionRateAvailable, setIsConversionRateAvailable] = useState(true);
  const isConversionRateRequired = fromDetails?.currency !== to?.currency;
  const [conversionRateLoading, setConversionRateLoading] = useState(false);
  const [conversionRate, setConversionRate] = useState(0);
  useEffect(() => {
    if (isConversionRateRequired && fromDetails?.currency && to?.currency && fromDetails?.currency !== to?.currency) {
      setConversionRateLoading(true);
      getConversionRate({
        baseCurrency: fromDetails?.currency,
        targetCurrency: to?.currency,
      }).then((res) => {
        setConversionRateLoading(false);
        setIsConversionRateAvailable(true);
        setConversionRate(res);
      }
      ).catch(() => {
        setConversionRateLoading(false);
        setIsConversionRateAvailable(false);
      });
    }
  }, [isConversionRateAvailable, fromDetails?.currency, to?.currency]);

  const transactionOptions = [
    {
      label: props.t("Self Transfer"),
      value: "self",
    },
    {
      label: props.t("Referral"),
      value: "referral",
    },
  ];

  if (enableClientToClientTransfer) {
    transactionOptions.push({
      label: props.t("Another Client"),
      value: "client",
    });
  }
  return (
    <React.Fragment >
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}>
        <i className="bx bx-plus me-1" />
        {props.t("Add New Internal Transfer")}
      </Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add New Internal Transfer")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={handleAddForexInternalTransfer}
          >
            <Row className="mb-3">
              <Col md="12">
                <div>
                  <AvFieldSelect
                    label={props.t("From")}
                    name="from"
                    type="select"
                    required
                    onChange={(e) => setFrom(e)}
                    isSearchable={true}
                    options={[
                      {
                        label: props.t("Live Account"),
                        value: "fx",
                      },
                      {
                        label: props.t("Live Wallet"),
                        value: "wallet",
                      },
                    ]}
                    classNamePrefix="select2-selection"
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row>
            {!props.customerId && <Row>
              <Col md="12">
                <SearchableComponent
                  placeholder={("Select Client")}
                  label={"Client"}
                  name={"client"}
                  onChange={(e) => {
                    e?.value ? setFromCustomer(e.value) : setFromCustomer(e);
                  }}
                  getData={
                    async (payload) => getClients({ payload })
                      .then((res) => {
                        if (props.customerId) {
                          const customer = res?.result?.docs?.find((client) => client._id === props.customerId?._id);
                          setFromCustomer({
                            value: customer,
                            label: `${customer?.fx?.isIb ? "IB" : "Client"} | ${customer.firstName} ${customer.lastName}`,
                          });
                          return customer ? [customer] : [];
                        } else {
                          return res?.result?.docs || [];
                        }
                      })
                  }
                  raw
                  value={props?.customerId ? fromCustomer : null}
                  mapper={(client) => ({
                    value: client,
                    label: `${client?.fx?.isIb ? "IB" : "Client"} | ${client.firstName} ${client.lastName}`,
                  })}
                />
              </Col>
            </Row>}
            {
              from === "wallet" ?
                <>
                  <Row>
                    {
                      walletsLoading ? <>
                        <TableLoader />
                      </> : <>
                        <Col md="12">
                          <div>
                            <AvFieldSelect
                              label={props.t("Wallet")}
                              name="fromWallet"
                              type="select"
                              required
                              isSearchable={true}
                              options={(wallets || []).map((wallet) => {
                                return {
                                  label: `${wallet?.asset} | BAL: ${wallet?.amount}`,
                                  value: {
                                    ...wallet,
                                    currency: wallet.asset,
                                  },
                                  isDisabled: wallet?.withdrawalDisabled,
                                };
                              })}
                              onChange={(e) => setFromDetails(e)}
                              classNamePrefix="select2-selection"
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>
                      </>
                    }
                  </Row>
                </>
                :
                <>
                  <Row>
                    {
                      accountsLoading ? <>
                        <TableLoader />
                      </> : <>
                        <Col md="12">
                          <div>
                            <AvFieldSelect
                              label={props.t("Trading Account From")}
                              name="fromAccount"
                              type="select"
                              required
                              isSearchable={true}
                              options={(accounts || [])
                                .filter((account) => account.type === "MAM_MASTER" ? account.profile?.profileType === 9 : true && account.type !== "DEMO")
                                .map((account) => {
                                  return {
                                    label: `${account?.platform} - ${account?.accountTypeId?.title} | ${account?.login} | BAL: ${account?.Balance} ${account?.currency}`,
                                    value: account,
                                    isDisabled: account?.withdrawalDisabled,
                                  };
                                })}
                              onChange={(e) => setFromDetails({
                                ...e,
                              })}
                              classNamePrefix="select2-selection"
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>
                      </>
                    }
                  </Row>
                </>
            }
            {
              // TODO: check if option is available
              <>
                <Row>
                  <Col md="12">
                    <AvFieldSelect
                      label={props.t("Type")}
                      name="type"
                      type="select"
                      required
                      placeholder={props.t("Select the Transaction Type")}
                      onChange={(e) => setIbTransactionType(e)}
                      isSearchable={true}
                      options={transactionOptions?.filter((option) => {
                        if (option?.value === "referral") {
                          return fromCustomer?.fx?.isIb;
                        }
                        return true;
                      }
                      )}
                      classNamePrefix="select2-selection"
                      validate={{ required: { value: true } }}
                    />
                  </Col>
                </Row>
              </>
            }
            <Row>
              {
                ibTransactionType === "self" &&
                <Col md="12">
                  <div>
                    <AvFieldSelect
                      label={props.t("To")}
                      name="toId"
                      type="select"
                      required
                      onChange={(e) => setTo(e)}
                      isSearchable={true}
                      options={getClientToOptions()}
                      classNamePrefix="select2-selection"
                      validate={{ required: { value: true } }}
                    />
                  </div>
                </Col>
              }
              {
                ibTransactionType === "referral" &&
                <Col md="12">
                  <SearchableComponent
                    placeholder={("Select Ib Client")}
                    label={"IB Client"}
                    name={"ibClient"}
                    onChange={(e) => {
                      e?.value ? setIbClient(e.value) : setIbClient(e);
                    }}
                    getData={async () => getReferrals({
                      payload: {
                        clientId: fromCustomer?._id,
                        type: "live",
                      }
                    }).then((res) => (res?.result?.[0]?.childs))
                    }
                    mapper={(client) => (
                      {
                        label: `${client?.fx?.isIb ? "IB" : "Client"} | ${client.firstName} ${client.lastName}`,
                        value: client
                      }
                    )}
                    value={ibClient}
                  />
                </Col>
              }
              {
                ibTransactionType === "client" &&
                <Col md="12">
                  <SearchableComponent
                    placeholder={("Select Client")}
                    label={"Client"}
                    name={"ibClient"}
                    onChange={(e) => {
                      e?.value ? setIbClient(e.value) : setIbClient(e);
                    }}
                    getData={async (payload) => getClients({
                      payload: {
                        ...payload,
                        fxType: "CLIENT",
                      }
                    }).then((res) => (res?.result?.docs))
                    }
                    mapper={(client) => (
                      {
                        label: `${client?.fx?.isIb ? "IB" : "Client"} | ${client.firstName} ${client.lastName}`,
                        value: client
                      }
                    )}
                    value={ibClient}
                  />
                </Col>
              }
            </Row>
            {
              ibClient && <Col md="12">
                <div>
                  <AsyncAvFieldSelect
                    label={props.t("To")}
                    name="toId"
                    type="select"
                    required
                    onChange={(e) => e?.value ? setTo(e.value) : setTo(e)}
                    isSearchable={true}
                    defaultOptions={toIbOptions}
                    classNamePrefix="select2-selection"
                    validate={{ required: { value: true } }}
                    defaultValue=""
                    value={to}
                  />
                </div>
              </Col>
            }
            <Row className="mb-3">
              <Col md="12">
                <AvField
                  name="amount"
                  label={props.t("Amount")}
                  placeholder={props.t("Enter Amount")}
                  type="number"
                  min="1"
                  onChange={(e) => setAmount(e.target.value)}
                  errorMessage={props.t("Enter Valid Amount")}
                  validate={{
                    required: { value: true }
                  }}
                />
              </Col>
            </Row>
            <div className='text-center pt-3 p-2'>
              {
                isConversionRateRequired && isConversionRateAvailable
                && amount > 0 && <>
                  <Alert
                    color="warning"
                  >A conversion rate is applied : {" "}
                    {(amount * parseFloat(conversionRate))?.toFixed(2)}
                    {" "}{to?.currency}</Alert>
                </>
              }
              {
                amount > 0 && !isAmountValid && <>
                  {props.t("Amount is greater than balance")}
                </>
              }
            </div>
            {/* <TransactionLimitAlert
              customerId={fromCustomer?._id ? fromCustomer?._id : props.customerId}
              amount={amount}
              isFx={from === "fx"}
              fetchCustomer={props.customerId ? false : true}
              title={props.t("Transfer")}
              isInternalTransfer={true}
            /> */}
            {
              create &&
              <Row>
                <Button
                  disabled={!fromDetails || !to || (isConversionRateRequired && !isConversionRateAvailable) || conversionRateLoading}
                  color="primary"
                  className="btn-block w-md waves-effect waves-light" type="submit">
                  {props.t("Create")}
                </Button>
              </Row>
            }
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default (withTranslation()(AddInternalTransferModal));