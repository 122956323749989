import { getTransactionSetting, updateTransactionLimit } from "apis/transactions";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Button,
  Container, Row, Spinner
} from "reactstrap";
import { showErrorNotification, showSuccessNotification } from "store/actions";

export default function TransferLimit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState({
    currency: "USD",
    wallet: {
      daily: 0,
      monthly: 0,
    },
    fx: {
      daily: 0,
      monthly: 0,
    },
  });
  const getData = () => {
    setLoading(true);
    getTransactionSetting({
      type: "INTERNAL_TRANSFER",
    }).then((res) => {
      setForm(res);
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  const handleValidSubmit = () => {
    const data = {
      type: "INTERNAL_TRANSFER",
      ...form,
    };
    if (form.wallet.daily > form.wallet.monthly) {
      dispatch(showErrorNotification("Daily limit must be less than monthly limit!"));
      return;
    }
    if (form.fx.daily > form.fx.monthly) {
      dispatch(showErrorNotification("Daily limit must be less than monthly limit!"));
      return;
    }
    setLoading(true);
    updateTransactionLimit(data).then(() => {
      dispatch(showSuccessNotification("Update successfully!"));
      getData();
    }).catch(() => {
      dispatch(showErrorNotification("Unable to update!"));
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      <Container className="w-100">
        <AvForm
          onValidSubmit={() => {
            handleValidSubmit();
          }}
        >
          <Row>
            <AvField
              name="currency"
              label={t("Currency")}
              type="text"
              value={form?.currency}
              disabled
            />
          </Row>
          <Row>
            <h5>{t("Wallet Limit")}</h5>
          </Row>
          <Row>
            <AvField
              name="wallet.daily"
              label={t("Daily Limit")}
              placeholder={t("Daily Limit")}
              type="number"
              value={form.wallet.daily}
              onChange={(e) => {
                setForm({
                  ...form,
                  wallet: {
                    ...form.wallet,
                    daily: e.target.value,
                  },
                });
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Please enter a valid daily limit"),
                },
              }}
            />
          </Row>
          <Row>
            <AvField
              name="wallet.monthly"
              label={t("Monthly Limit")}
              placeholder={t("Monthly Limit")}
              type="number"
              value={form.wallet.monthly}
              onChange={(e) => {
                setForm({
                  ...form,
                  wallet: {
                    ...form.wallet,
                    monthly: e.target.value,
                  },
                });
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Please enter a valid Monthly limit"),
                },
              }}
            />
          </Row>
          <Row>
            <h5>{t("Fx Limit")}</h5>
          </Row>
          <Row>
            <AvField
              name="fx.daily"
              label={t("Daily Limit")}
              placeholder={t("Daily Limit")}
              type="number"
              value={form.fx.daily}
              onChange={(e) => {
                setForm({
                  ...form,
                  fx: {
                    ...form.fx,
                    daily: e.target.value,
                  },
                });
              }} 
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Please enter a valid Daily limit"),
                },
              }}
            />
          </Row>
          <Row>
            <AvField
              name="fx.monthly"
              label={t("Monthly Limit")}
              placeholder={t("Monthly Limit")}
              type="number"
              value={form.fx.monthly}
              onChange={(e) => {
                setForm({
                  ...form,
                  fx: {
                    ...form.fx,
                    monthly: e.target.value,
                  },
                });
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Please enter a valid Monthly limit"),
                },
              }}
            />
          </Row>
          <Row className="d-flex justify-content-center">
            <Button
              className="btn btn-primary btn-lg btn-block mt-3" 
              type="submit"
              color="primary"
            >
              {loading ? <>
                <Spinner size="sm" />
              </> : t("Save")}
            </Button>
          </Row>
        </AvForm>
      </Container>
    </div>
  );
}
