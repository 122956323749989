import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Row,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import useModal from "hooks/useModal";
import { changeTransferLimit } from "apis/client";
import { showSuccessNotification } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { fetchAssetsStart } from "store/assests/actions";
import { fetchClientDetails } from "store/client/actions";

function TransferLimit(props) {
  const dispatch = useDispatch();
  const [show, toggle] = useModal();
  const [loading, setLoading] = useState(false);
  const { transferLimit = 0, transferLimitCurrency = "USD", transferredAmount = 0 } = useSelector(state => state?.clientReducer?.clientDetails?.settings);
  const { assets = [], loading: assetsLoading } = useSelector(state => state.assetReducer);
  useEffect(()=>{
    loadAssets(1, 1000);
  }, []);
  
  const loadAssets = ( page, limit) => {
    dispatch(fetchAssetsStart({
      limit,
      page
    })) ;
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggle}
      >
        Transfer Limit
      </button>
      <Modal isOpen={show} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          Change Transfer Limit
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              setLoading(true);
              changeTransferLimit({
                clientId: props.clientId,
                transferLimit: v.transferLimit,
                transferLimitCurrency: v.transferLimitCurrency
              }).then(() => {
                setLoading(false);
                dispatch(showSuccessNotification("Transfer Limit Changed Successfully"));
                toggle();
                dispatch(fetchClientDetails(props.clientId));
              }).catch(() => {
                dispatch(showSuccessNotification("Something went wrong"));
                setLoading(false);
              });
            }}
          >
            <Row>
              <AvField
                type="number"
                label="Transferred Amount"
                name="transferredAmount"
                value={transferredAmount ?? 0}
                disabled
                isDisabled
              />
            </Row>
            <Row>
              <AvField
                type="number"
                label="Transfer Limit"
                name="transferLimit"
                value={transferLimit}
                validate={{
                  required: { value: true },
                }}
              />
            </Row>
            <Row>
              <AvFieldSelect
                type="select"
                label="Transfer Limit Currency"
                name="transferLimitCurrency"
                value={transferLimitCurrency}
                validate={{
                  required: { value: true },
                }}
                options={assets.map(asset => ({
                  label: asset.name,
                  value: asset.symbol
                }))
                }
              />
            </Row>
            <div className="text-center p-2">
              <Button type="submit" disabled={loading || assetsLoading} color="primary">Change</Button>
            </div>

          </AvForm>
        </ModalBody>
      </Modal>
    </>

  );
}
export default TransferLimit;