import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line object-curly-newline
import { Card, CardBody, CardHeader, CardTitle, Row } from "reactstrap";
import MamProfilesBodyCol from "./MamProfilesBodyCol";
import { getAccountDetailsByLoginsAPI } from "apis/tradingAccounts";
import FeatherIcon from "feather-icons-react";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "react-super-responsive-table";
import SkeletonTableLoader from "components/Loaders/SkeletonTableLoader";

const MasterTradingState = ({ profile }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [masterTradingState, setMasterTradingState] = useState({
    login: profile?.masterTradingAccountId?.login,
    Equity: profile?.masterTradingAccountId?.Equity ?? 0,
    Balance: profile?.masterTradingAccountId?.Balance ?? 0,
    FreeMargin: profile?.masterTradingAccountId?.FreeMargin ?? 0,
  });
  const [feeTradingState, setFeeTradingState] = useState({
    amount: profile?.feesId?.amount,
    currency: profile?.feesId?.asset,
  });
  useEffect(() => {
    fetchData();
  }, [profile]);

  useEffect(() => {
    if (profile?.feesId) {
      setFeeTradingState({
        amount: profile?.feesId?.amount,
        currency: profile?.feesId?.asset,
      });
    }
  }, [profile?.feesId]);

  const fetchData = () => {
    setLoading(true);
    getAccountDetailsByLoginsAPI({
      loginString: profile?.masterTradingAccountId?.login,
    }).then((res) => {
      if (res?.result?.docs) {
        const masterTradingAccount = res?.result?.docs?.find(
          (account) => account?.login === profile?.masterTradingAccountId?.login
        );
        setMasterTradingState(masterTradingAccount);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Card className="h-100">
      <CardHeader className="d-flex justify-content-center align-items-center">
        <CardTitle className="color-primary">{t("Master Trading State")}</CardTitle>
        <FeatherIcon
          icon="refresh-cw"
          className="icon-lg ms-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            fetchData();
          }}
        />
      </CardHeader>
      <CardBody className="d-flex flex-column gap-2">
        <Table id="tech-companies-1"
          className="table">
          <Thead className="text-center table-light" >
            <Tr>
              <Th className="color-primary" >{t("Type")}</Th>
              <Th className="color-primary" >{t("Login")}</Th>
              <Th className="color-primary" >{t("Equity")}</Th>
              <Th className="color-primary" >{t("Balance")}</Th>
              <Th className="color-primary" >{t("Free Margin")}</Th>
            </Tr>
          </Thead>
          <Tbody className="text-center">
            {
              loading ? <SkeletonTableLoader rows={2} columns={5} /> : (
                <>
                  <Tr>
                    <Td className="color-primary">{"Master"}</Td>
                    <Td className="color-primary">{masterTradingState?.login ?? "0"}</Td>
                    <Td className="color-primary">{masterTradingState?.Equity ?? "0"}</Td>
                    <Td className="color-primary">{masterTradingState?.Balance ?? "0"}</Td>
                    <Td className="color-primary">{masterTradingState?.FreeMargin ?? "0"}</Td>
                  </Tr>
                  <Tr>
                    <Td className="color-primary">{"Fee"}</Td>
                    <Td className="color-primary">{"-"}</Td>
                    <Td className="color-primary">{"-"}</Td>
                    <Td className="color-primary">{feeTradingState?.amount ?? 0 } {` ${feeTradingState?.currency ?? ""}`}</Td>
                    <Td className="color-primary">{"-"}</Td>
                  </Tr>
                </>
              )
            }
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default MasterTradingState;
