/* eslint-disable indent */
import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import WithdrawalLimit from "./WithdrawalLimit";
import TransferLimit from "./TransferLimit";
const TransactionLimits = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState("1");
  const tabs = [
    {
      tabId: "1",
      navLinkName: "Withdrawal",
      component: <WithdrawalLimit />
    },
    {
      tabId: "2",
      navLinkName: "Internal Transfer",
      component: <TransferLimit />,
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t("Transaction Limits")}</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Nav tabs>
                    {tabs.map((tabItem) => (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active: activeTab === tabItem.tabId,
                            })}
                            onClick={() => {
                              toggle(tabItem.tabId);
                            }}
                          >
                            {tabItem.navLinkName}
                          </NavLink>
                        </NavItem>
                      </>
                    ))}
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    {tabs.map((tabItem) => (
                      <>
                        <TabPane tabId={tabItem.tabId}>
                          <Row>
                            <Col sm="12">{tabItem.component}</Col>
                          </Row>
                        </TabPane>
                      </>
                    ))}
                  </TabContent>
                </CardBody>
              </Card> 
            </Col>
          </Row>
        </Container>
        <Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(TransactionLimits);
