import { getAccountDetailsByLoginsAPI } from "apis/tradingAccounts";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line object-curly-newline
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import {
  Card,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import SkeletonTableLoader from "components/Loaders/SkeletonTableLoader";

const Investors = ({ investors: inv, setSelectedInvestor }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [investors, setInvestors] = useState([]);
  useEffect(() => {
    setInvestors(inv);

    return () => {
      setInvestors([]);
    };
  }, []);

  useEffect(() => {
    if (inv?.length > 0) {
      fetchData();
    }
  }, [inv]);


  const fetchData = () => {
    setLoading(true);
    getAccountDetailsByLoginsAPI({
      loginString: inv?.map((item) => item.login).join(","),
    }).then((res) => {
      if (res?.result?.docs) {
        setInvestors(res?.result?.docs);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const total = investors?.reduce((acc, cur) => acc + parseFloat(cur.Equity), 0);


  return (
    <div>
      <h3 className="text-center">{t("Investors")}<FeatherIcon
        icon="refresh-cw"
        className="icon-lg ms-2"
        style={{ cursor: "pointer" }}
        onClick={() => {
          fetchData();
        }}
      /></h3>
      <Card className="p-3 mt-4">
        <Table id="tech-companies-1"
          className="table">
          <Thead className="text-center table-light">
            <Tr>
              <Th className="py-2 color-primary text-start">{t("Login")}</Th>
              <Th className="py-2 color-primary text-start">{t("Equity")}</Th>
              <Th className="py-2 color-primary">{t("Share %")}</Th>
              <Th className="py-2 color-primary">{t("Balance")}</Th>
              <Th className="py-2 color-primary">{t("Free Margin")}</Th>
            </Tr>
          </Thead>
          <Tbody className="text-center">
            { loading ? <SkeletonTableLoader columns={4} rows={inv?.length || 1} /> : <>
              {inv?.length === 0 && (
                <>
                  <Tr>
                    <Td colSpan="9 color-primary">{t("No Investors found.")}</Td>
                  </Tr>
                </>
              )}
              {(inv?.length > 0) && investors?.map((item) => (
                <Tr key={item.login}
                  onClick={() => {
                    setSelectedInvestor({
                      ...item,
                      tradingAccountId: item._id,
                    });
                  }}
                >
                  <Td className="text-start fw-bold color-primary" >{item.login}</Td>
                  <Td className="text-start ">{item.Equity ?? 0}</Td>
                  <Td className="">{parseFloat(parseFloat(item.Equity) * 100 / total)?.toFixed(2) ?? 0}</Td>
                  <Td>{item.Balance ?? 0}</Td>
                  <Td>{item.FreeMargin ?? 0}</Td>
                </Tr>
              ))}
            </>}
          </Tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Investors;