import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Row,
  Col,
  
} from "reactstrap";
function ViewLimits(props){
  const [open, setOpen] = useState(false);
  const clientDetails = props.clientDetails;
  const toggleModal = ()=>{
    setOpen(!open);
  };
  console.log(clientDetails?.settings?.transactionSettings);
  return (
    <React.Fragment>
      <button 
        type="button" 
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        View  Transaction Limit
      </button>
      <Modal isOpen={open} toggle={toggleModal} centered={true} size="lg">
        <ModalHeader toggle={toggleModal}>
          View Available Transaction Limit
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} className="text-center">
              <h5>Withdrawal ({(clientDetails?.settings?.transactionSettings?.withdrawal?.wallet?.limit?.currency)})</h5>
            </Col>
            <Col sm={12} className="my-1">
              <h6>Wallet</h6>
              <div>
                <Label>Daily</Label>
                <Input
                  type="number"
                  name="transferPerTransaction"
                  defaultValue={clientDetails?.settings?.transactionSettings?.withdrawal?.wallet?.limit?.wallet?.daily - clientDetails?.settings?.transactionSettings?.withdrawal?.wallet?.daily}
                  disabled
                />
              </div>
              <div>
                <Label>Monthly</Label>
                <Input
                  type="number"
                  name="transferPerTransaction"
                  defaultValue={(clientDetails?.settings?.transactionSettings?.withdrawal?.wallet?.limit?.wallet?.monthly) - (clientDetails?.settings?.transactionSettings?.withdrawal?.wallet?.monthly)}
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} className="my-1">
              <h6>Fx</h6>
              <div>
                <Label>Daily</Label>
                <Input
                  type="number"
                  name="transferPerTransaction"
                  defaultValue={clientDetails?.settings?.transactionSettings?.withdrawal?.fx?.limit?.fx?.daily - clientDetails?.settings?.transactionSettings?.withdrawal?.fx?.daily}
                  disabled
                />
              </div>
              <div>
                <Label>Monthly</Label>
                <Input
                  type="number"
                  name="transferPerTransaction"
                  defaultValue={clientDetails?.settings?.transactionSettings?.withdrawal?.fx?.limit?.fx?.monthly - clientDetails?.settings?.transactionSettings?.withdrawal?.fx?.monthly}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="text-center">
              <h5>Transfer ({(clientDetails?.settings?.transactionSettings?.withdrawal?.wallet?.limit?.currency)})</h5>
            </Col>
            <Col sm={12} className="my-1">
              <h6>Wallet</h6>
              <div>
                <Label>Daily</Label>
                <Input
                  type="number"
                  name="transferPerTransaction"
                  defaultValue={(clientDetails?.settings?.transactionSettings?.transfer?.wallet?.limit?.wallet?.daily) - clientDetails?.settings?.transactionSettings?.transfer?.wallet?.daily}
                  disabled
                />
              </div>
              <div>
                <Label>Monthly</Label>
                <Input
                  type="number"
                  name="transferPerTransaction"
                  defaultValue={clientDetails?.settings?.transactionSettings?.transfer?.wallet?.limit?.wallet?.monthly - clientDetails?.settings?.transactionSettings?.transfer?.wallet?.monthly}
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} className="my-1">
              <h6>Fx</h6>
              <div>
                <Label>Daily</Label>
                <Input
                  type="number"
                  name="transferPerTransaction"
                  defaultValue={clientDetails?.settings?.transactionSettings?.transfer?.fx?.limit?.fx?.daily - clientDetails?.settings?.transactionSettings?.transfer?.fx?.daily}
                  disabled
                />
              </div>
              <div>
                <Label>Monthly</Label>
                <Input
                  type="number"
                  name="transferPerTransaction"
                  defaultValue={clientDetails?.settings?.transactionSettings?.transfer?.fx?.limit?.fx?.monthly - clientDetails?.settings?.transactionSettings?.transfer?.fx?.monthly}
                  disabled
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );

}

export default ViewLimits;