import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Container, Row } from "react-bootstrap";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import MasterTradingState from "./MasterTradingState";
import FeeType from "./FeeType";
import AccountDetails from "./AccountDetails";
import Investors from "./Investors";
import MasterAccountPositions from "./MasterAccountPositions";
import { useDispatch, useSelector } from "react-redux";
import { createMamProfile, fetchMamProfiles } from "store/mam-pam/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "reactstrap";
import ApproveDetailsModal from "pages/Requests/Master/ApproveDetailsModal";
import { getProfileName } from "pages/Requests/Master/data";
import InvestorPosition from "./InvestorPosition";
import CustomSelect from "components/Common/CustomSelect";

const MamProfiles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [handleModal, setHandleModal] = useState(false);
  const { profiles, loading } = useSelector((state) => ({
    profiles: state.mamPamReducer.profile?.profiles,
    loading: state.mamPamReducer.profile?.loading,
  }));
  const toggleModal = () => setHandleModal(!handleModal);
  const [selectedInvestor, setSelectedInvestor] = useState(null);

  const { clientId } = useParams();
  useEffect(() => {
    dispatch(fetchMamProfiles({
      customerId: clientId,
      page: 1,
      limit: 100,
    }));
  }, []);

  useEffect(() => {
    if (profiles?.length > 0) {
      setSelectedProfile(profiles[0]);
      setSelectedInvestor(profiles[0]?.slaves?.[0]);
    }
  }, [profiles]);

  return (
    <Container fluid className="gx-4 gap-4 d-flex flex-column" style={{
      minHeight: "60vh",
    }}>
      <AvForm className="d-flex justify-content-between">
        <div className="d-flex">
          {
            loading ? <Spinner /> : (
              <>
                <div className="row m-2">
                  <label>{t("Select Profile")}</label>
                  <CustomSelect
                    className="w-full"
                    name="profile"
                    onChange={(e) => {
                      setSelectedProfile(e.value);
                    }}
                    value={selectedProfile === null ? "" : undefined}
                    options={(profiles || [])?.map((profile) => {
                      return {
                        label: `${getProfileName(profile?.profileType)} | ${profile?.masterTradingAccountId?.login} | ${profile?.slaves?.length} Members`,
                        value: profile,
                      };
                    })}
                  />
                </div>
              </>
            )
          }
          <div className="row col-md-auto align-content-end m-2">
            <Button
              onClick={() => {
                setSelectedProfile(null);
              }}
            >
              {t("Clear")}
            </Button>
          </div>
        </div>
        <div className="d-flex align-items-end  m-2">
          <div>
            <Button color="danger" onClick={toggleModal}>
              {t("New Copy Trading Profile")}
            </Button>
            <ApproveDetailsModal isOpen={handleModal} toggleModal={() => {
              toggleModal();
            }}  handler={(values) =>{
              dispatch(createMamProfile({
                ...values,
                customerId: clientId,
              }));
            }} title={t("New Copy Trading Profile")}
            />
          </div>
        </div>
      </AvForm>
      {
        selectedProfile && (
          <>
            <Row>
              <Col md={6} lg={4}>
                <MasterTradingState profile={selectedProfile} />
              </Col>
              <Col md={6} lg={4}>
                <FeeType profile={selectedProfile} />
              </Col>
              <Col md={6} lg={4}>
                <AccountDetails profile={selectedProfile} />
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={12}>
                <MasterAccountPositions masterTradingAccountId={selectedProfile?.masterTradingAccountId?._id} />
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={12}>
                <Investors  investors={selectedProfile?.slaves} setSelectedInvestor={setSelectedInvestor} />
              </Col>
              <Col sm={12} lg={12}>
                <InvestorPosition tradingAccount={selectedInvestor} />
              </Col>
            </Row>
          </>
        )
      }
    </Container>
  );
};

export default MamProfiles;
