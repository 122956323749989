import { getClients } from "apis/client";
import SearchableComponent from "pages/Transactions/Forex/internalTransfer/SearchableComponents";
import React from "react";

export default function SearchableInvestor({
  investor, setInvestor
}) {
  async function getClientsWithParams({ searchText, page, limit }) {
    return getClients({
      payload: {
        type: "LIVE",
        fx: {
          isInvestor: true
        },
        searchText: searchText,
        page: page,
        limit: limit
      }
    }).then((res) => (res?.result?.docs || []));
  }

  return (
    <SearchableComponent
      placeholder={("Select Investor")}
      label={"Investor"}
      name={"investor"}
      onChange={(e) => {
        e?.value ? setInvestor(e.value) : setInvestor(e);
      }}
      getData={
        async (params) => {
          const { searchText, page, limit } = params;
          return getClientsWithParams({
            searchText,
            page,
            limit
          });
        }
      }

      mapper={(client)=> (
        {
          label: `${client.firstName} ${client.lastName}`,
          value: client
        }
      )}
      value={investor}
    />
  );
}
