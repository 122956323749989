import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector,
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "components/Common/TableLoader";
import SearchableProfile from "./SearchableComponents/SearchableProfile";
import { AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { fetchClientDetails } from "store/client/actions";
import { unlinkMasterFromInvestor } from "store/mam-pam/actions";

function UnLinkInvestor({ isLead, kyc = true, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    link: { loading },
    clearingCounter,
  } = useSelector((state) => state.mamPamReducer);
  const [linkClientModal, setLinkClientModal] = useState(false);
  const [profile, setProfile] = useState();
  const toggleModal = () => {
    setLinkClientModal(!linkClientModal);
  };

  useEffect(() => {
    if (linkClientModal) {
      dispatch(fetchClientDetails(id));
      toggleModal();
    }
  }, [clearingCounter]);

  const handleConfirm = (e, v) => {
    dispatch(unlinkMasterFromInvestor({
      customerId: id,
      investorId: v?.investorAccount,
      profileId: v?.profile?._id,
    }));
  };

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("UnLink Investor")}
      </button>
      <Modal isOpen={linkClientModal} toggle={toggleModal} centered={true}>
        <AvForm
          onValidSubmit={(e, v) => handleConfirm(e, v)}
        >
          <ModalHeader toggle={toggleModal}>
            {t("UnLink Investors to SP")}
          </ModalHeader>
          <ModalBody >
            <Row>
              <Col>
                <SearchableProfile
                  customerId={id}
                  profile={profile}
                  setProfile={setProfile}
                />
              </Col>
            </Row>
            {
              profile && <>
                <AvFieldSelect
                  name="investorAccount"
                  label={t("Investor Account")}
                  options={profile?.slaves?.map((item) => ({
                    label: `${item?.login} | ${item.type} `,
                    value: item?.tradingAccountId,
                  })) || []}
                  required
                />
              </>
            }
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModal} color="secondary">
              {t("No")}
            </Button>
            {loading ? <Loader /> : (
              <Button type="submit" color="primary" disabled={!kyc || !profile}>
                {t("Yes")}
              </Button>
            )}
          </ModalFooter>
        </AvForm>
      </Modal>
    </React.Fragment >
  );
}

export default (UnLinkInvestor);