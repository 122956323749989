import * as axiosHelper from "./api_helper";
import qs from "qs";

// fetch forex deposits
export const getForexDeposits = async ({ payload }) => {
  const result = await axiosHelper.get(`/fxtransactions/deposits?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

// add forex deposit
export const postForexDeposit = async ({ payload }) => {
  const result = await axiosHelper.postFormData("/fxtransactions/deposits", payload);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const rejectFxDeposit = async (values) => {
  const result = await axiosHelper.patch(`/fxtransactions/deposit/${values.id}/reject`, { ...values });
  if (result.isError){
    throw new Error(result.message);
  }

  return result;
};

export const editFxDeposit = async (values)=>{
  const result = await axiosHelper.patch(`/fxtransactions/deposit/${values.id}/edit`, { ...values });
  if (result.isError){
    throw new Error(result.message);
  }

  return result;
};