import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert, Col, Row
} from "reactstrap";
import { fetchClientDetails } from "store/client/actions";

export default function TransactionLimitAlert({
  customerId, amount = 0, fetchCustomer = true, isFx = true, title = "Withdrawal",
  isInternalTransfer = false,
}) {
  const { clientDetails } = useSelector((state) => state.clientReducer);
  const { t } = useTranslation();
  const dispatch =  useDispatch();
  useEffect(() => {
    fetchCustomer && customerId && dispatch(fetchClientDetails(customerId));
  }, [customerId, fetchCustomer]);
  return (
    <div>
      {
        customerId && clientDetails?.settings && <Row>
          <Col sm={12}>
            <Alert color="info">
              {t("Client's Daily available " + title + " Limit is")}
              {" "}
              {
                clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.[isFx ? "fx" : "wallet"]?.daily - clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.daily - amount < 0
                  ?
                  0 :
                  clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.[isFx ? "fx" : "wallet"]?.daily - clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.daily - amount}
              {` ${clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.currency}`}
            </Alert>
          </Col>
          <Col sm={12}>
            <Alert color="info">
              {t("Client's Monthly available " + title + " Limit is")}
              {" "}
              {clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.[isFx ? "fx" : "wallet"]?.monthly - clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.monthly - amount < 0 ?
                0 :
                clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.[isFx ? "fx" : "wallet"]?.monthly - clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.monthly - amount}
              {` ${clientDetails?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.currency}`}
            </Alert>
          </Col>
        </Row>
      }
    </div>
  );
}
