import logo from "assets/images/brands/brand-main-sm.png";
import Sidebarlogo from "assets/images/brands/sidebar.png";

export const clientName = "Rox Capitals";
export const developedBy = "Rox Capitals";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "Rox Capitals";
export const sidebarLogo = Sidebarlogo;
export const mainLogo = logo;
export const lightLogo = logo;
