import * as axiosHelper from "./api_helper";
import qs from "qs";

export const getClientTransactions = async ({ payload })=>{
  const records = await axiosHelper.get(`/transactions/?${qs.stringify(payload)}`);
  return records.result;
};

export const getTransactionSetting = async (params) => {
  const res = await axiosHelper.get(`/transaction-settings/setting?${qs.stringify(params)}`);
  if (res.isError) {
    throw new Error(res.message);
  }
  return res.result;
};

export const updateTransactionLimit = async (params) => {
  const res = await axiosHelper.post("/transaction-settings/setting", params);
  if (res.isError) {
    throw new Error(res.message);
  }
  return res.result;
};